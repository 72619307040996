<script setup lang="ts">
import {
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger,
} from 'radix-vue'
import {
  type Slots,
  useSlots,
} from 'vue'

import AppText from '@/components/app/text/AppText.vue'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'

const props = withDefaults(
  defineProps<{
    isDisabled?: boolean
    asChild?: boolean
    canCopy?: boolean
    color?: DefaultThemeColor
    delayDuration?: number
    disableHoverableContent?: boolean
    maxWidth?: string
    side?: 'bottom' | 'left' | 'right' | 'top'
    value?: string | null
  }>(),
  {
    isDisabled: false,
    asChild: false,
    color: 'lavender-indigo',
    delayDuration: 0,
    disableHoverableContent: false,
    maxWidth: undefined,
    side: 'top',
    value: null,
  },
)

const slots: Slots = useSlots()
</script>

<template>
  <TooltipProvider :delay-duration="props.delayDuration">
    <TooltipRoot
      :disable-hoverable-content="props.disableHoverableContent"
      disable-closing-trigger
    >
      <TooltipTrigger :as-child="props.asChild">
        <slot />
      </TooltipTrigger>
      <TooltipPortal v-if="(props.value && !props.isDisabled) || slots['custom-content']">
        <TooltipContent
          :class="[
            `bg-${props.color}`,
          ]"
          :side="props.side"
          :side-offset="5"
          :style="{ maxWidth: props.maxWidth }"
          class="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade z-[9999] rounded-lg px-4 py-2 text-white will-change-[transform,opacity]"
        >
          <AppText
            :value="props.value"
            :can-copy="props.canCopy"
            color="white"
            is-html
          />
          <slot name="custom-content" />
          <TooltipArrow
            :class="`fill-${props.color}`"
            size="8"
          />
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
</template>
